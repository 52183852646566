<template lang="pug">
.action-marketplace
  SectionHeader(:header="header")

  .action__body
    .action__section
      .action__grid
        .action__form-field
          .input-smart__title
            | Дата регистрации с
          DatePicker(
            type="date"
            v-model="from"
          )
        .action__form-field
          .input-smart__title
            | Дата регистрации по
          DatePicker(
            type="date"
            v-model="to"
          )
        .action__form-field
          .input-smart__title
            | Продавец
          InputNumber(v-model="sellerId" placeholder="ID продавца")
        .action__form-field
          .input-smart__title
            | Домен продавца
          BaseSelect(
            v-model="domain"
            :options="domains"
            with-empty-value
            placeholder="Домен"
          )

      Button.sellers__filters-btn(is-primary @click="newSearch") Искать

    .sellers__sellersList(v-if="sellers")
      .sellers__seller(v-for="seller in sellers" :key="seller.id")
        .sellers__main
          span
            span.seller__id {{ seller.id }}
            span.seller__name {{ seller.full_name }}
          Button.seller__chat(is-primary @click="openChat(seller.id)") Чат
        .seller__contacts
          span.seller__h Дата
          span.seller__phone {{ seller.created_at }}
        .seller__contacts
          span.seller__h Первый товар
          span.seller__phone {{ seller.first_item }}
        .seller__contacts
          span.seller__h Контакты
          span.seller__phone +{{ seller.phone }}
          span.seller__email {{ seller.email }}
          span.seller__email(v-if="seller.telegram_username") tg: {{ seller.telegram_username }}
        .seller__domain
          span.seller__lang {{ seller.language }}
        .seller__stat
          span.seller__counter
            span.seller__h Продаж:
            | {{ seller.orders_count }}
          span.seller__counter
            span.seller__h В продаже:
            | {{ seller.items_count }}
          span.seller__counter
            span.seller__h На модерации:
            | {{ seller.not_moderated_count }}

  .pagination
    Pagination(:pagesCount="pagesCount" :perPage="pageSize" :currentPage="page" @pageChange="setPage" @perPageChange="setPerPage")
</template>

<script>
import SectionHeader from '@/components/SectionHeader/SectionHeader'
import Pagination from '@/components/Marketplace/Pagination/Pagination'
import InputNumber from "@/components/InputNumber/InputNumber";
import BaseSelect from "@/components/BaseSelect/BaseSelect";
import DatePicker from "@/components/DatePicker/DatePicker";
import {mapMutations} from "vuex";

export default {
  name: 'Marketplace',

  components: {
    BaseSelect,
    DatePicker,
    InputNumber,
    SectionHeader,
    Pagination,
  },

  data () {
    return {
      page: 0,
      pageSize: 12,
      sellers: undefined,
      sellersAmount: 0,
      sellerId: '',
      from: undefined,
      to: undefined,
      domain: '',
      domains: [
        {
          title: 'Россия',
          value: 'ru'
        },
        {
          title: 'Европа',
          value: 'en'
        }
      ]
    }
  },

  computed: {
    header() {
      return {
        title: 'Продавцы'
      }
    },

    pagesCount () {
      return Math.ceil((this.sellersAmount || 0) / this.pageSize)
    }
  },

  mounted () {
    this.getSellers()
  },
  methods: {
    ...mapMutations('chat', ['setChatId', 'setChatName']),

    openChat (id) {
      this.setChatId(`0_${id}`)
      this.setChatName('Чат')
    },

    async getSellers () {
      this.$loading.start()

      const params = {
        page: this.page + 1,
        size: this.pageSize
      }

      if (this.sellerId && this.sellerId !== '' && +this.sellerId > 0) {
        params.seller_id = this.sellerId
      }

      if (this.from) {
        const [startDay, startMonth, startYear] = this.from.split('.')
        params.from = `${startYear}-${startMonth}-${startDay}`
      }

      if (this.to) {
        const [endDay, endMonth, endYear] = this.to.split('.')
        params.to = `${endYear}-${endMonth}-${endDay}`
      }

      if (this.domain && this.domain !== '') {
        params.domain = this.domain
      }

      try {
        const { data } = await this.$axios.get(
            '/marketplace/sellers',
            {
              params,
            }
        )

        this.sellers = data.data.sellers
        this.sellersAmount = data.data.count
      } catch (e) {
        alert('Ошибка получения списка продавцов')
      }

      this.$loading.finish()
    },

    newSearch () {
      this.setPage(0)
    },

    setPage (pageNumber) {
      this.page = pageNumber
      this.getSellers()
    },

    setPerPage (perPage) {
      this.pageSize = perPage
      this.setPage(0)
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.seller {
  &__h {
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 130%;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__contacts {
    max-width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 4px;
  }

  &__stat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;
  }

  &__id {
    font-size: 16px;
    color: $blue-grey10;
    font-weight: 500;
    line-height: 100%;
    padding: 0 4px;
  }

  &__phone {
    font-size: 16px;
    color: $blue-grey10;
    font-weight: 500;
    line-height: 100%;
    padding: 0 4px;
  }

  &__email {
    font-size: 16px;
    color: $primary60;
    font-weight: 500;
    line-height: 100%;
    padding: 0 4px;
  }

  &__name {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    color: $white;
    font-weight: 500;
    line-height: 100%;
  }
}

.sellers {
  &__seller {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    padding: 8px 16px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    flex-wrap: wrap;
    background: $blue-grey60;
    border-radius: 8px;

    &:hover {
      background: $blue-grey50;
    }
  }

  &__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__sellersList {
    color: $white;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    align-items: center;
  }

  &__filters {
    margin: 12px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    &-btn {
      margin: 12px 0;
    }
  }
}
</style>
